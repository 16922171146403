<template>
  <b-container class='mt-4'>
    <b-row>
      <b-col cols='10'>
        <h4>SFTP Remote Servers</h4>
      </b-col>
      <b-col cols='2'>
        <b-button :disabled="loading" class='float-right' @click="() => this.$router.push('/sftp/remote-servers/create')" variant='primary'>
          Add Remote Server
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if='loading' class='mt-4'>
      <b-col cols='12'>
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <div v-else>
      <b-row class='mt-4'>
        <b-col cols='5'>
          <b-form-group label='Connection Key'
                        label-for='input-key'>
            <b-form-input id='input-key'
                          v-model='filters.connection_key'
                          type='text' />
          </b-form-group>
        </b-col>
        <b-col cols='5'>
          <b-form-group label='Hostname'
                        label-for='input-hostname'>
            <b-form-input id='input-hostname'
                          v-model='filters.hostname'
                          type='text' />
          </b-form-group>
        </b-col>
        <b-col cols='1' class='align-self-end'>
          <div class='form-group'>
            <b-button variant='primary' @click='searchByFilter' style='width: 100px'>Search</b-button>
          </div>
        </b-col>
        <b-col cols='1' class='align-self-end'>
          <div class='form-group'>
            <b-button variant='warning' @click='resetFilters' style='width: 100px' class='ml-0'>Reset</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols='12'>
          <b-table striped hover fixed :items='remoteServers' :fields='fields' id='remote-servers-table'>
            <template #cell(created_at)='row'>
              {{ dateNormalizer(row.value) }}
            </template>
            <template #cell(updated_at)='row'>
              {{ dateNormalizer(row.value) }}
            </template>
            <template #cell(actions)='row'>
              <b-row>
                <b-col cols='7'>
                  <b-button
                    v-b-tooltip.hover
                    title='Edit'
                    variant='primary'
                    size='sm'
                    :to="'/sftp/remote-servers/' + row.item.uuid">
                    See Details
                  </b-button>
                </b-col>
                <b-col cols='5'>
                  <b-button
                    v-b-tooltip.hover
                    title='Delete Server'
                    variant='danger'
                    size='sm'
                    @click='openModalHandler(row.item.uuid)'>
                    Delete
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-pagination
        v-model='currentPage'
        :total-rows='rows'
        :per-page='perPage'
        aria-controls='remote-servers-table'
        @change='updatePage'>
      </b-pagination>
      <b-modal ref='modal-remove-server' hide-footer title='Remove Remote Server Config'>
        <div class='d-block text-center'>
          <p>Are you sure you want to delete this remote server?</p>
          <div class='mt-5'>
            <b-form-group
              label='Provide a reason for this action'
              label-for='quarantine-actions-modal-comment'
              label-align='left'
              class='mt-3'>
            </b-form-group>
            <b-form-textarea
              id='quarantine-actions-modal-comment'
              placeholder='Reason to proceed'
              v-model='modal.comment'
              rows='3'
              max-rows='6' />
          </div>
        </div>
        <b-button class='mt-3' variant='danger' block @click='removeServer'>I'm Sure</b-button>
        <b-button class='mt-2' variant='info' block @click='closeModal'>Cancel</b-button>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import * as R from 'ramda';
import moment from 'moment';

export default {
  name: 'SFTPRemoteServersList',
  data() {
    return {
      loading: true,
      remoteServers: [],
      currentPage: 1,
      rows: 0,
      perPage: 10,
      offset: 0,
      limit: 10,
      filters: {
        connection_key: null,
        hostname: null,
      },
      fields: [
        {
          key: 'connection_key',
          label: 'Connection Key',
        },
        {
          key: 'hostname',
          label: 'Hostname',
        },
        {
          key: 'port',
          label: 'Port',
        },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
        {
          key: 'updated_at',
          label: 'Updated At',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-center',
        },
      ],
      modal: {
        uuid: null,
        comment: null,
      },
    };
  },
  async beforeMount() {
    await this.getRemoteServersList();
  },
  methods: {
    async getRemoteServersList() {
      this.loading = true;
      const payload = {
        filters: this.filters,
        pagination: {
          limit: this.limit,
          offset: this.offset,
        },
      };
      return this.$store.dispatch('Sftp/RemoteServer/getRemoteServersList', payload)
        .then(({ data: { servers, count } }) => {
          this.remoteServers = servers;
          this.rows = count;
        }).catch(err => {
          console.error(err);
          this.$noty.error(`Unable to fetch external pull configs: ${R.pathOr(err, [ 'response', 'data', 'message' ], err)}`);
        }).finally(() => {
          this.loading = false;
        });
    },
    async updatePage(event) {
      this.currentPage = event;
      this.offset = event * this.perPage - this.perPage;
      await this.getRemoteServersList();
    },
    dateNormalizer(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    searchByFilter() {
      this.currentPage = 1;
      this.offset = 0;
      this.limit = 10;
      return this.getRemoteServersList();
    },
    resetFilters() {
      this.filters = {
        connection_key: null,
        hostname: null,
      };
      this.currentPage = 1;
      this.offset = 0;
      this.limit = 10;
      return this.getRemoteServersList();
    },
    async removeServer() {
      if ((!this.modal.comment || this.modal.comment.length < 10)) {
        this.$noty.error('Please provide a comment to proceed. It should at least be 10 characters long');
        return Promise.reject();
      }
      this.loading = true;
      return this.$store.dispatch('Sftp/RemoteServer/removeRemoteServerConfig', {
        data: {
          uuid: this.modal.uuid,
          comment: this.modal.comment,
        },
      })
        .then(() => {
          this.$noty.success('Remote server removed successfully');
          this.getRemoteServersList();
        })
        .catch(err => {
          this.$noty.error(err.message);
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
          this.closeModal();
        });
    },
    openModalHandler(uuid) {
      this.modal.uuid = uuid;
      this.$refs['modal-remove-server'].show();
    },
    closeModal() {
      this.modal.uuid = null;
      this.modal.comment = null;
      this.$refs['modal-remove-server'].hide();
    },
  },
};
</script>
